<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Change Feature Course
        <feather-icon icon="Edit2Icon" />
      </h4>

    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 60%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="12">
            <b-col md="12">
              <b-form-group
                label="Course "
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules="required"
                >
                  <v-select
                  data-testid="new-feature-course"
                    v-model="addCourseForm.course"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="coursesList"
                    :reduce="(val) => val.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Select  Instructor"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Instructor"
                  rules="required"
                >
                  <v-select
                  data-testid="new-feature-instructor"
                    v-model="addCourseForm.instructor"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="instructorList"
                    :reduce="(val) => val.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Location"
                label-for="blog-edit-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="location"
                  rules="required"
                >
                  <b-form-input
                  data-testid="new-feature-location"
                    v-model="addCourseForm.location"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  Logo
                </h4>
                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >

                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <b-card-text class="my-50" />
                    <div class="d-inline-block">
                      <b-form-group
                        label="Tab Image "
                        label-for="blog-edit-title"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Tab Image "
                          rules="required"
                        >
                          <b-form-file
                          data-testid="new-feature-logo"
                            ref="refInputE2"
                            v-model="addCourseForm.logo"
                            accept=".jpg, .png, .gif, .jpeg"
                            placeholder="Choose file"
                            @input="inputImageRendererTab"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
            data-testid="new-feature-submit-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'

export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const FeatureCourse = ref(false)
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const coursesList = ref([])
    const instructorList = ref([])
    const form = ref({
      course: '',
      instructor: '',
      logo: '',
      location: '',

    })
    const addCourseForm = reactive({
      course: '',
      instructor: '',
      logo: '',
      location: '',

    })
    store.dispatch('homepage/getAllCourses').then(response => {
      coursesList.value = response.data.data
    })

    store.dispatch('homepage/GetFeatureCourse').then(response => {
      if (response.data.data.length > 0) {
        FeatureCourse.value = true
      }
      addCourseForm.value = response.data.data[0]
    })
    store.dispatch('instructor/getAllinstructor').then(response => {
      instructorList.value = response.data.data
    })

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          if (FeatureCourse.value == true) {
            formData.append('course_id', addCourseForm.value.course)
            formData.append('instructor_id', addCourseForm.value.instructor.id)
            formData.append('logo', addCourseForm.value.logo)
            formData.append('location', addCourseForm.value.location)
            formData.append('_method', 'put')
            const { id } = addCourseForm.value

            store
              .dispatch('homepage/updateFeature', { id, formData })
              .then(response => {
                Vue.swal({
                  title: 'Updated',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                Vue.swal({
                  title: '',
                  text: `${error.response.data.message}`,
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          } else {
            formData.append('course_id', addCourseForm.course)
            formData.append('instructor_id', addCourseForm.instructor)
            formData.append('logo', addCourseForm.logo)
            formData.append('location', addCourseForm.location)

            store
              .dispatch('homepage/CreateFeature', formData)
              .then(response => {
                Vue.swal({
                  title: 'Added',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                Vue.swal({
                  title: '',
                  text: `${error.response.data.message}`,
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          }
        }
      })
    }
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
      instructorList,
      form,
      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,

    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
  .mb-2.col-12 {
    margin: -1px !important;
  }
   .border.rounded.p-2 {
    margin: 8px 1px !important;
  }
  .col-12 {
    margin: 0px;
  }

  </style>
